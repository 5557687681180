.radio {
  display: grid;
  grid-template-columns: 5% 90%;
  gap: 8px;
  align-items: center;
  align-content: center;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
}

.interactive-choice.two-lines .radio {
  grid-template-columns: 3% 90%;
  gap: 16px;
}

.interactive-choice.two-lines.active .content-normal.dark-grey {
  font-weight: 400 !important;
}

.interactive-choice.two-lines .radio-content {
  padding: 0px;
}

.interactive-choice.active .radio .radio-mark.round:after,
.checkbox .radio-mark.round-left:after {
  background-color: white;
}

.input-box.radio-with-label label {
  width: 93%;
}

.radio input {
  display: none;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.radio-content {
  padding-left: 8px;
  color: var(--grey);
}

.radio-content p {
  color: var(--grey);
}

.radio-mark {
  border: 1px solid #4ea699;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: transparent;
  transition: background-color 0.3s linear;
}

.radio-mark.error {
  border: 3px solid red;
}

.radio-mark.square {
  border-radius: 2px;
  top: 5px;
}

.radio-container.square .radio-content {
  padding-left: 6.5px;
}

.radio-mark.middle {
  top: 35%;
}

.radio-mark.middle.round-left {
  top: 30%;
}

.active .radio .radio-mark {
  background-color: #4ea699;
}

.active .radio .radio-mark.round {
  background-color: transparent;
}

.radio-mark:after {
  content: '';
  display: none;
}

.active .radio .radio-mark:after {
  display: block;
}

.radio.round {
  padding-left: 40px;
}

.radio .radio-mark.round:after,
.radio .radio-mark.round-left:after {
  margin: 2px 0 0 2px;
  height: 8px;
  width: 8px;
  background-color: #4ea699;
  border-radius: 50%;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
}

.radio-mark.round {
  height: 14px;
  width: 14px;
}

.interactive-choice.two-lines .radio-mark.round {
  margin-bottom: 3px;
}

.radio-mark.round-left {
  height: 15px;
  width: 15px;
}

.interactive-choices .radio-mark.round {
  border-width: 3px;
}

.interactive-choices {
  width: 100%;
  display: grid;
  grid-template-columns: 49% 50%;
  column-gap: 8px;
}

.interactive-choices.two-lines {
  row-gap: 8px;
}

.interactive-choice {
  background-color: #f7f8fa;
  border-radius: 8px;
  display: grid;
  padding: 12px 0 12px 16px;
  align-items: center;
  margin-bottom: 24px;
}

.interactive-choice.two-lines {
  min-height: 48px;
  margin-bottom: 0px;
}

.interactive-choice.active {
  background-color: #4ea699;
}

.interactive-choice.active .radio-mark {
  border-color: white;
}

.interactive-choice span {
  display: block;
}

.interactive-choice.active span,
.interactive-choice.active p {
  color: white;
  font-weight: 700 !important;
}

.interactive-choice p {
  margin-bottom: 0 !important;
}

.interactive-choice p {
  display: grid;
  align-content: center;
}

.interactive-choice.double p {
  grid-template-rows: 15px 25px;
}

.interactive-choice.active .checkbox .radio-mark.round:after,
.checkbox .radio-mark.round-left:after {
  background-color: white;
}

.interactive-choice.active > label > div > span > b {
  color: white;
}

.interactive-choice .content-title {
  color: #808282;
}

.radio-container-subtitle {
  margin-bottom: 16px;
}
