.header-view-status-container {
  z-index: 10;
  margin-bottom: 64px;
  padding-bottom: 8px;
}

.header-view-status-container.hasSubheader {
  box-shadow: 0px 2px 4px 0px #8082821a;
}

.header-view-status-container.x {
  box-shadow: none;
}

.header-view-status-container.hasSubheader.active {
  box-shadow: none;
}

.arrow.green path {
  fill: #4ea699;
}

.header {
  margin-top: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-items: center;
  justify-content: space-between;
  position: relative;
}

.header .logo {
  margin-top: 0px;
}

.image {
  margin-bottom: 0px !important;
}

.header-right-menu {
  display: grid;
  justify-content: center;
  grid-template-columns: auto auto auto auto auto;
  align-items: center;
  column-gap: 8px;
  color: #808282;
  fill: #808282;
}

.header-right-menu .content-bold.active {
  color: #4ea699;
}

.header-right-menu .logout.active g path {
  fill: #ef476f;
}

.menu-header {
  position: absolute;
  top: 50px;
  right: 0;
  width: 346px;
  height: auto;
  z-index: 250;
  background-color: white;
  padding: 16px 16px;
  text-align: left;
  border: 1px solid #dddede;
  border-radius: 4px;
  box-shadow: 0px 4px 4px 0px #8082821a;
}

.menu-header-body {
  position: relative;
}

.menu-header-body .close-icon {
  cursor: pointer;
  position: absolute;
  top: 0%;
  right: 0%;
}

.menu-header-body h3 {
  margin-bottom: 0 !important;
}

.menu-header-body .header-section {
  background: #f7f8fa;
  display: grid;
  grid-template-columns: 10% 83% 5%;
  padding: 18px 16px 18px 12px;
  max-height: 20px;
  max-width: 324px;
  justify-content: center;
  justify-items: start;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 8px;
}

.header-section.logout-section p {
  color: white;
}

.menu-header-body .header-section.logout-section {
  background-color: #ef476f;
  color: white;
}

.menu-header .link-icon path {
  fill: #1b2021;
}

.float-button {
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--primary-color);
  border: 2px solid var(--primary-color);
  position: fixed;
  width: 50px;
  height: 48.01px;
  bottom: 2%;
  right: 2%;
  border-radius: 12px;
}

.pass-button {
  color: white;
  z-index: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--primary-color);
  border: 2px solid var(--primary-color);
  position: fixed;
  width: 54px;
  height: 48.01px;
  min-width: 50px;
  bottom: 10%;
  right: 2%;
  border-radius: 12px;
}

.submenu-close {
  position: absolute;
  bottom: 0%;
  width: 102%;
  left: -1%;
  padding: 39px;
  border-radius: 0;
}
