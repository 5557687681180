.input-box {
  text-align: left;
  position: relative;
  margin: 0 auto;
  justify-content: space-between;
  display: flex;
  width: 100%;
  align-items: baseline;
  margin-bottom: 16px;
}

.input-inner-container {
  width: 100%;
  position: relative;
}

.input-with-icon-container {
  display: grid;
  grid-template-columns: 20% 80%;
}

.input-icon {
  display: grid;
  height: 22px;
  border: 1px solid var(--border-color);
  border-radius: 3px;
  padding: 12px 0px 12px 7px;
  border-right: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.input-icon div {
  display: grid;
  grid-template-columns: 25% 50% 25%;
  align-items: center;
  justify-content: center;
  justify-items: center;
  align-content: center;
}

.icon-container {
  z-index: 2;
  cursor: pointer;
}

.icon-container .icon {
  justify-self: center;
}

.input-icon svg {
  justify-self: start;
}

.input-icon:after {
  content: '|';
  color: var(--light-grey);
  padding-left: calc(20% - 10px);
  position: absolute;
}

.input-icon-focused {
  box-shadow: 0px 3px 0px var(--primary-color);
}

.input-box.row-input-field {
  flex-direction: column;
}

.input-box.row-input-field .label {
  width: auto;
}

.input-box .input-label {
  display: grid;
  grid-template-columns: auto auto;
  width: 50%;
}

.two-fields label {
  margin-top: 10px;
}

.input-box.row-input-field .label {
  margin-bottom: 8px;
}

.input-box.row-input-field .label label {
  display: flex;
  align-items: center;
  gap: 8px;
}

.input-box.row-input-field .label svg {
  cursor: pointer;
}

.input-box input {
  width: 100%;
  height: 48px;
  outline: none;
  border: 1px solid var(--border-color);
  box-sizing: border-box;
  border-radius: 4px;
  margin-right: 10px;
  border-radius: 3px;
  padding: 12px 0px 12px 16px;
}

.input-has-icon {
  border-left: 0 !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  padding-left: 16px !important;
}

.label:has(.pass-hint) {
  display: flex;
  grid-template-columns: auto auto;
}

.input-box input[disabled] {
  border: 1px solid var(--light-grey);
}

.input-box input:focus,
.input-box input.focused {
  box-shadow: 0px 3px 0px var(--primary-color);
}

.input-box-error {
  box-shadow: 0px 3px 0px #ef476f !important;
}

.error-icon-new {
  position: absolute;
  top: 18px;
  right: 16px;
}

.error-icon-iban svg {
  top: calc(50% - 20px);
}

.input-box-additional-element .input-container {
  width: 80%;
}

.input-box-additional-element .error-icon-new {
  right: 70px;
}

.additional-element,
.additional-element-copy {
  color: #808282;
  cursor: pointer;
  transition: opacity 0.5s;
  transition-timing-function: ease-in-out;
  opacity: 1;
  position: absolute;
  bottom: 40px;
  right: 11px;
  top: 17px;
}

.hide {
  opacity: 0;
  visibility: hidden;
}

.additional-element-text {
  color: rgba(27, 32, 33, 0.5);
  line-height: 120%;
  position: absolute;
  display: block;
  right: 6px;
  top: 15px;
  z-index: 9;
}

.additional-element.withError {
  color: rgba(27, 32, 33, 0.5);
  cursor: pointer;
  line-height: 120%;
}

/* Change autocomplete styles in WebKit */
input:-webkit-autofill,
input:-webkit-autofill:hover {
  -webkit-box-shadow: 0 0 0px 1000px var(--background-color) inset;
  -webkit-text-fill-color: var(--text-color) !important;
}
input:-webkit-autofill:focus {
  border-bottom: 3px solid var(--button-color);
  -webkit-box-shadow: 0 0 0px 1000px var(--background-color) inset;
  -webkit-text-fill-color: var(--text-color) !important;
}

.tooltip-text {
  visibility: hidden;
  position: absolute;
  z-index: 2;
  width: 200px;
  color: #1b2021;
  font-size: 12px;
  background-color: #e4e7e7;
  border-radius: 10px;
  padding: 10px 15px 10px 15px;
}

.tooltip-text:before {
  content: '';
  position: absolute;
  transform: rotate(45deg);
  padding: 5px;
  z-index: 1;
}

.hover-text:hover .tooltip-text {
  visibility: visible;
}

#top {
  top: -40px;
  left: -50%;
}

#top:before {
  top: 80%;
  left: 45%;
}

#bottom {
  top: 25px;
}

#bottom:before {
  top: -5%;
  left: 24%;
}

#left {
  top: -8px;
  right: 120%;
}

#left:before {
  top: 35%;
  left: 94%;
}

#right {
  top: -8px;
  left: 120%;
}

#right:before {
  top: 35%;
  left: -2%;
}

.hover-text {
  display: flex;
  align-self: center;
  position: relative;
  text-align: center;
  color: #c6c7c7;
}
