.left-content-register div {
  align-self: center;
  padding-right: 10px;
}

.auth-image-container.register .container-background {
  position: absolute;
  background-image: url('../images/register-image.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
}

.auth-form-container.register .error-message,
.auth-form-container.register .error-message {
  background-color: var(--error);
}

.auth-form-container.register .error-message h2,
.auth-form-container.register .error-message p {
  color: white;
}

.register-form {
  min-height: 720px;
  padding: 0px 120px;
  justify-content: flex-end;
  flex-direction: column;
  display: flex;
}
