.layout {
  margin-top: 64px !important;
  min-height: calc(100vh - 110px);
}

.section {
  margin-bottom: 60px;
}

.address-input .outlined-input {
  margin-bottom: 20px;
}

.contact-input .outlined-input {
  margin-bottom: 20px;
}

.section:nth-child(1) {
  margin-top: 40px;
}

.h-auto {
  height: auto !important;
}

.h40 {
  height: 40vh !important;
}

.h50 {
  height: 50vh !important;
}

.h60 {
  height: 60vh !important;
}
.h80 {
  height: 70vh !important;
}

.h100 {
  height: 100vh !important;
}

.min-h60 {
  min-height: 60vh;
}

.min-h80 {
  min-height: 80vh;
}

.min-h100 {
  min-height: 100vh;
}

.w60 {
  width: 60vw !important;
}

.w80 {
  width: 70vw !important;
}

.w100 {
  width: 100vw !important;
}

.min-w60 {
  min-width: 60vw;
}

.min-w80 {
  min-width: 70vw;
}

.min-w100 {
  min-width: 100vw;
}

.w100p {
  width: 100% !important;
}

.h100p {
  height: 100% !important;
}

.margin-auto {
  margin: auto;
}

.margin-left-10 {
  margin-left: 10px;
}

.left {
  text-align: left;
}

.float-left {
  float: left;
}

.right {
  text-align: right;
}

.float-right {
  float: right;
}

.center {
  text-align: center;
}

.flex {
  display: flex;
}

.grid {
  display: grid;
}

.grid-template .template-body {
  align-self: end;
}

.grid-template .button-group {
  align-self: end;
}

.flex-template {
  display: flex;
  height: calc(100vh - 250px);
  flex-direction: column;
  justify-content: space-evenly;
}

.template-body {
  width: 100%;
  margin: 0 auto;
}

.column {
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: column;
}

.grid--flex--center {
  justify-content: center;
  align-items: center;
  text-align: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly !important;
}

.items-center {
  align-items: center;
}

.template-body .outlined-input {
  margin-bottom: 20px;
}

.outlined-input.b2c {
  margin-bottom: 20px;
}

.credit-check-title {
  margin-top: 72px;
  margin-bottom: 8px;
}

.status-title {
  margin-bottom: 24px;
}

.pricing-details {
  display: grid;
  grid-template-columns: 28% 72%;
  align-items: end;
}

.pricing-details p {
  margin-bottom: 4px;
}
