.progress {
  width: auto;
  margin: -15px 0 48px 0;
}

.progress-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20px, 1fr));
}

.progress-item {
  display: grid;
  position: relative;
  justify-content: center;
  align-items: center;
  justify-items: flex-start;
  grid-template-rows: 90% 10%;
  height: 4px;
  background: var(--light-grey);
  margin-left: 4px;
  border-radius: 4px;
}

.progress-item.clickable {
  cursor: pointer;
}

.progress-item svg {
  width: 12px;
  height: 12px;
}

.progress-item .progress-step-name {
  width: 100px;
  text-align: center;
  color: #edeef0;
}

.progress-item .progress-tooltip {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%) translateY(-10px);
  background: var(--text-color);
  padding: 8px;
  white-space: nowrap;
  border-radius: 4px;
  color: #fff;
  text-align: center;
  z-index: 10;
  line-height: 1 !important;
}

.progress-item .progress-tooltip::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 6px solid var(--text-color);
  margin-top: -1px;
}

.progress-item.completed {
  background: var(--secondary-color);
}

.progress-item.active {
  background: var(--secondary-button-color);
}

.progress-item:first-child .progress-step-name {
  text-align: center;
}

.progress-item:last-child .progress-step-name {
  text-align: center;
}

.progress-item:first-child::before {
  content: none;
}

.progress-item:last-child::after {
  content: none;
}
