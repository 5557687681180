.numbered-list-item {
  display: grid;
  grid-template-columns: 20% 80%;
  margin: 20px 0;
}

.numbered-list .number {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 60px;
  border: 1px solid #e2e2e2;
  border-radius: 80%;
}

.IDNow-overlay {
  position: fixed;
  top: 0;
  left: 50%;
  width: 100vw;
  height: 100vh;
  transform: translate3d(-50%, 0, 0);
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 10;
}

.IDNow-overlay div {
  color: white;
  position: absolute;
  width: 400px;
  top: calc(50% - 120px);
  left: calc(50% - 200px);
}

.IDNow-overlay span {
  cursor: pointer;
}

.idnow-checkbox {
  margin-top: 36px;
}

.idnow-checkbox label span {
  align-self: baseline;
}
