.coverflow-container {
  grid-template-rows: 85% 10% !important;
  grid-template-columns: 100%;
}

.coverflow-container .template-body {
  align-self: center !important;
}

.coverflow {
  margin-left: 0px;
  display: inline-grid;
  grid-template-columns: auto auto auto auto auto;
  grid-template-rows: 100% 0;
  grid-gap: 0;
  width: 220px;
}

.coverflow input[type="file"] {
  display: none;
  position: absolute;
  top: calc(0% - 20px);
  left: calc(0% - 130px);
  padding: 45px 0;
}

.content {
  width: 30px;
  height: 30px;
  position: relative;
  border-radius: 50%;
  background: var(--primary-color);
  margin-bottom: 5vh;
}

.file-upload-icon {
  cursor: pointer;
  color: white;
  fill: white;
}

.coverflow-container .button-container {
  display: grid;
  justify-content: center;
  height: 200px;
}

.button-container button {
  margin: 0 !important;
}
