.dot-typing {
  position: relative;
  left: -9999px;
  margin-left: 7px;
  width: 4px;
  height: 4px;
  border-radius: 10px;
  background-color: white;
  color: white;
  animation: dot-typing 1s infinite linear;
}

.dot-typing.red {
  background-color: #ef476f;
  color: #ef476f;
  animation: dot-typing-red 1s infinite linear;
}

.dot-typing.blue {
  background-color: #5264a5;
  color: #5264a5;
  animation: dot-typing-blue 1s infinite linear;
}

@keyframes dot-typing {
  0% {
    box-shadow:
      9994px 0 0 0 rgba(255, 255, 255, 0.5),
      9999px 0 0 0 white,
      10004px 0 0 0 white;
  }
  16.667% {
    box-shadow:
      9994px -3px 0 0 white,
      9999px 0 0 0 white,
      10004px 0 0 0 white;
  }
  33.333% {
    box-shadow:
      9994px 0 0 0 white,
      9999px 0 0 0 rgba(255, 255, 255, 0.5),
      10004px 0 0 0 white;
  }
  50% {
    box-shadow:
      9994px 0 0 0 white,
      9999px -3px 0 0 white,
      10004px 0 0 0 white;
  }
  66.667% {
    box-shadow:
      9994px 0 0 0 white,
      9999px 0 0 0 white,
      10004px 0 0 0 rgba(255, 255, 255, 0.5);
  }
  83.333% {
    box-shadow:
      9994px 0 0 0 white,
      9999px 0 0 0 white,
      10004px -3px 0 0 white;
  }
  100% {
    box-shadow:
      9994px 0 0 0 white,
      9999px 0 0 0 white,
      10004px 0 0 0 white;
  }
}

@keyframes dot-typing-blue {
  0% {
    box-shadow:
      9994px 0 0 0 rgba(82, 100, 165, 0.5),
      9999px 0 0 0 #5264a5,
      10004px 0 0 0 #5264a5;
  }
  16.667% {
    box-shadow:
      9994px -3px 0 0 #5264a5,
      9999px 0 0 0 #5264a5,
      10004px 0 0 0 #5264a5;
  }
  33.333% {
    box-shadow:
      9994px 0 0 0 #5264a5,
      9999px 0 0 0 rgba(82, 100, 165, 0.5),
      10004px 0 0 0 #5264a5;
  }
  50% {
    box-shadow:
      9994px 0 0 0 #5264a5,
      9999px -3px 0 0 #5264a5,
      10004px 0 0 0 #5264a5;
  }
  66.667% {
    box-shadow:
      9994px 0 0 0 #5264a5,
      9999px 0 0 0 #5264a5,
      10004px 0 0 0 rgba(82, 100, 165, 0.5);
  }
  83.333% {
    box-shadow:
      9994px 0 0 0 #5264a5,
      9999px 0 0 0 #5264a5,
      10004px -3px 0 0 #5264a5;
  }
  100% {
    box-shadow:
      9994px 0 0 0 #5264a5,
      9999px 0 0 0 #5264a5,
      10004px 0 0 0 #5264a5;
  }
}

@keyframes dot-typing-red {
  0% {
    box-shadow:
      9994px 0 0 0 rgba(239, 71, 111, 0.5),
      9999px 0 0 0 #ef476f,
      10004px 0 0 0 #ef476f;
  }
  16.667% {
    box-shadow:
      9994px -3px 0 0 #ef476f,
      9999px 0 0 0 #ef476f,
      10004px 0 0 0 #ef476f;
  }
  33.333% {
    box-shadow:
      9994px 0 0 0 #ef476f,
      9999px 0 0 0 rgba(239, 71, 111, 0.5),
      10004px 0 0 0 #ef476f;
  }
  50% {
    box-shadow:
      9994px 0 0 0 #ef476f,
      9999px -3px 0 0 #ef476f,
      10004px 0 0 0 #ef476f;
  }
  66.667% {
    box-shadow:
      9994px 0 0 0 #ef476f,
      9999px 0 0 0 #ef476f,
      10004px 0 0 0 rgba(239, 71, 111, 0.5);
  }
  83.333% {
    box-shadow:
      9994px 0 0 0 #ef476f,
      9999px 0 0 0 #ef476f,
      10004px -3px 0 0 #ef476f;
  }
  100% {
    box-shadow:
      9994px 0 0 0 #ef476f,
      9999px 0 0 0 #ef476f,
      10004px 0 0 0 #ef476f;
  }
}
