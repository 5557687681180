.collapsible-arrow {
  place-self: inherit;
  margin-bottom: 5px;
}

.collapsible-arrow:hover {
  cursor: pointer;
}

.collapsible-arrow svg path {
  stroke: var(--text-color);
  fill: var(--text-color);
  color: var(--text-color);
}

.collapsible-item {
  height: 48px;
  display: grid;
  grid-template-columns: 65% 35%;
  align-self: center;
  background-color: #f7f8fa;
  border-radius: 4px;
  padding: 12px 16px;
  margin-bottom: 8px;
}

.collapsible-title {
  display: flex;
  align-self: center;
  flex-direction: column;
  justify-self: left;
  width: 100%;
}

.collapsible-action {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 24px;
}

.collapsible-action div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.actions-button {
  cursor: pointer;
}
