.contracts-history .contracts-container {
  margin-top: 48px;
}

.contracts .interactive-choices {
  grid-template-columns: 32.2% 32.2% 32.2%;
  gap: 16px;
  margin-top: 24px;
  margin-bottom: 48px;
}

.contract-download {
  margin-top: 48px;
  margin-bottom: 104px;
}

.contract-download.contract-page {
  margin-top: 16px;
  margin-bottom: 48px;
}

.contract-download .content-title {
  margin-bottom: 0px;
}

.contracts-history .header-view-status-container .header .logo {
  margin: 16px 0;
}

.contract-download .contract {
  margin-top: 24px;
  background-color: #4ea699;
  height: 40px;
  border-radius: 8px;
  padding: 26px 26px 28px 17px;
  display: grid;
  grid-template-columns: 77% 22%;
  cursor: pointer;
  align-items: center;
  align-content: center;
}

.contract-download .contract div:nth-child(1) {
  display: grid;
  grid-template-columns: auto 92%;
  gap: 16px;
  align-items: center;
  align-content: center;
}

.contract-download .contract div:nth-child(2) {
  display: grid;
  grid-template-columns: auto 90%;
  gap: 4px;
  align-items: center;
  align-content: center;
  justify-self: end;
}

.contract-download .contract.small {
  margin-top: 24px;
  background-color: #4ea699;
  height: 40px;
  border-radius: 8px;
  padding: 28px 24px;
  display: grid;
  grid-template-columns: 70% 30%;
  cursor: pointer;
  align-items: center;
  align-content: center;
}

.contract-download .contract.small div:nth-child(1) {
  display: grid;
  grid-template-columns: 15% 85%;
  align-items: center;
  align-content: center;
}

.contract-download .contract.small div:nth-child(2) {
  display: grid;
  grid-template-columns: 20% 80%;
  align-items: center;
  align-content: center;
}

.contract-download .contract p {
  color: white;
  margin-bottom: 0;
}

.tab-closer {
  float: right;
  margin-top: 48px;
  margin-bottom: 59px;
}
