.animation-layout {
  position: relative;
  height: 270px;
  width: 400px;
  padding-bottom: 48px;
}

.animate {
  position: absolute;
  top: 20%;
  left: 5%;
}

.animate:nth-child(1) {
  top: 54%;
  left: 44%;
  width: 50px;
  z-index: 2;
}

.animate:nth-child(1) .animate {
  animation: linear AnimatePen 3s;
  -moz-animation: linear AnimatePen 3s;
  -webkit-animation: linear AnimatePen 3s;
  -o-animation: linear AnimatePen 3s;
  animation-iteration-count: infinite;
}

.animate:nth-child(2) {
  top: 61%;
  left: 41%;
  width: 120px;
}

.animate:nth-child(2) .animate {
  animation: linear Rotate360 3s;
  -moz-animation: linear Rotate360 3s;
  -webkit-animation: linear Rotate360 3s;
  -o-animation: linear Rotate360 3s;
  animation-iteration-count: infinite;
}

.animate:nth-child(3) {
  top: 50%;
  left: 9%;
  width: 120px;
}

.animate:nth-child(3) .animate {
  animation: linear Rotate360AntiClockWise 3s;
  -moz-animation: linear Rotate360AntiClockWise 3s;
  -webkit-animation: linear Rotate360AntiClockWise 3s;
  -o-animation: linear Rotate360AntiClockWise 3s;
  animation-iteration-count: infinite;
}

.animate:nth-child(4) {
  z-index: 1;
  width: 150px;
  top: 37%;
  left: 32%;
}

.animate:nth-child(5) {
  z-index: 0;
  width: 400px;
}

@keyframes AnimatePen {
  0% {
    top: 36%;
    left: 34%;
  }
  25% {
    top: 36%;
    left: 47%;
  }
  50% {
    top: 53%;
    left: 34%;
  }
  75% {
    top: 53%;
    left: 47%;
  }

  100% {
    top: 36%;
    left: 34%;
  }
}

@keyframes Rotate360 {
  100% {
    -moz-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes Rotate360AntiClockWise {
  100% {
    -moz-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}
