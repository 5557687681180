.notification-layout {
  background: #dcedeb;
  width: 330px;
  min-height: 100px;
  position: fixed;
  top: 10%;
  right: 2%;
  color: var(--secondary-color);
  padding: 16px;
  border-radius: 5px;
  z-index: 250;
}

.notification-layout.danger {
  background: #fbd1db;
}

.notification-close {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}

.notification-loading {
  position: absolute;
  bottom: 10%;
  width: 2px;
  height: 2px;
  background-color: #fff;
}

.notification-layout .notification-close path {
  fill: var(--secondary-color);
  stroke: var(--secondary-color);
}

.notification-layout.danger .notification-close path {
  fill: #ef476f;
  stroke: #ef476f;
}

.notification-title svg {
  stroke-width: 3px;
  width: 16px;
  height: 16px;
  margin-bottom: -3px;
  margin-left: -4px;
  padding-right: 8px;
}

.notification-layout .notification-title {
  color: var(--secondary-color);
  margin-bottom: 8px;
}

.notification-layout .notification-body {
  color: var(--secondary-color);
}

.notification-layout.danger .notification-title {
  color: #ef476f;
  margin-bottom: 8px;
}

.notification-layout.danger .notification-body {
  color: #ef476f;
}
