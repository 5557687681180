.subtitle-beneficial-owners {
  font: normal normal 700 15px/24px 'Inter' !important;
  padding-top: 48px;
  padding-bottom: 12px;
}

.second-title-beneficial-owners {
  padding-top: 72px;
}

.person-list-beneficial-owners {
  padding-top: 48px;
}

.radio-container {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

