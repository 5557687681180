.auth-image-container.login .container-background {
  position: absolute;
  background-image: url('../images/login-image.jpeg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
  width: 100%;
  height: 100%;
  z-index: -5;
}

.login-form {
  min-height: 550px;
  padding: 0px 120px;
  justify-content: flex-end;
  flex-direction: column;
  display: flex;
}

.forgot-link {
  margin-bottom: 48px;
  float: right;
}

.auth-form .input-container {
  margin-bottom: 24px;
}

.auth-form .input-container .input-box {
  align-items: flex-start;
}

.auth-form .input-container label {
  font-weight: 700 !important;
  color: var(--text-color);
}

.auth-form-container.login .error-message,
.auth-form-container.login .error-message {
  background-color: var(--error);
}

.auth-form-container.login .error-message h2,
.auth-form-container.login .error-message p {
  color: white;
}
