.dark .header svg path,
.dark .step-name-current-not-started,
.dark .step-name-current-in-progress,
.dark .step-name-complete,
.dark .step-circle-title,
.dark .confirm-item_groups__1drNb,
.dark .confirm-item_groups__1drNb h6,
.dark input,
.dark .button_button-choice__2Tyf0,
.dark .card .sub-thin,
.dark .card .baseline-bold,
.dark .card path,
.dark .modal path {
  color: white !important;
  stroke: white !important;
  fill: white;
}

.dark .logoText {
  fill: white;
}

.dark .button_button__3_GL1.active,
.dark .button_button--secondary__5cP0Q,
.dark .outlined-input input:focus,
.dark input,
.dark .select,
.dark .select:focus,
.dark .card {
  border-color: white !important;
  color: white !important;
}

.dark .outlined-input label {
  color: #808080 !important;
}

.dark button:disabled,
.dark .button_button--secondary__5cP0Q {
  color: rgba(255, 255, 255, 0.3);
  box-shadow: none;
  background-color: rgba(255, 255, 255, 0.12);
}

.dark input,
.dark .button_button-choice__2Tyf0,
.dark .options,
.dark .card,
.dark .modal-container,
.dark .button-group.scrollable {
  background-color: #1b1b1b !important;
}

.dark .options.visible,
.dark .card {
  border: 1px solid white !important;
}
