.add-new-car {
  margin: 24px 0 25px 0;
  max-width: 710px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.add-new-car p,
.add-new-car svg path {
  padding-bottom: 8px;
}

.overview-container {
  margin-top: 75px;
  background-color: #4ea699;
  padding: 25px;
  min-height: 500px;
  position: relative;
}

.overview-container .couple-photo {
  position: absolute;
  left: -15%;
  top: 10%;
  width: 330px;
}

.overview-container h2,
.overview-container p {
  color: white;
}

.overview-container .text-box {
  width: 70%;
  float: right;
  padding-top: 100px;
  margin-right: 50px;
}

.overview-container .text-box button {
  margin-top: 70px;
  float: right;
}

.overview-container.mobile-only {
  display: none;
}

.overview-container .content-title {
  color: white;
}
