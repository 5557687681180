.sequence {
  display: flex;
  justify-content: space-between;
  padding: 0 0 0 30px;
  flex-direction: row;
}

.button-wrapper {
  display: flex;
  padding: 10px 30px 30px 30px;
  justify-content: center;
}

.description-text {
  margin: 16px 0px 10px 0px;
}

.description-section {
  margin-bottom: 48px;
}

.sequence-steps {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.single-step {
  display: flex;
  flex-direction: column;
  width: 244px;
}

.line {
  width: 100%;
  display: flex;
  height: 20px;
  align-self: center;
}

.status-name-desc {
  text-align: left;
  margin-top: 64px;
  display: flex;
}

.status-name-subtitle {
  color: #808080;
}

.circlesbox {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  justify-content: space-around;
}

.circlesbox .edit-pen {
  padding: 0 15px;
}

.circlesbox:before {
  content: '';
  position: absolute;
  z-index: 0;
  top: 52%;
  -webkit-transform: translateX(53%);
  transform: translateX(53%);
  height: 1px;
  width: 100%;
  background: #d9d9d9;
}

.single-step:first-of-type .line .circlesbox:before {
  transform: translateX(52%);
  width: 650px;
}

.single-step:first-of-type.flex .line .circlesbox:before {
  transform: translateX(52%);
  width: 500px;
}

.single-step:last-of-type .line .circlesbox:before {
  width: 80%;
  transform: translateX(-50%);
}

.circle-new {
  position: relative;
  z-index: 0;
  background: #d9d9d9;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-left: 30px;
}

.edit-pen {
  box-shadow: 0 0 0 10px var(--background-color);
  position: relative;
  z-index: 2;
  background: var(--background-color);
  width: 20px;
  height: 20px;
}

.edit-pen svg {
  padding-bottom: 10px;
  width: 20px;
  height: 20px;
}

.status-overview-steps {
  text-align: left;
  padding-top: 48px;
  padding-bottom: 48px;
}

.status-overview-content {
  margin: 96px 0;
}
