.add-person-button {
  width: 104px !important;
  height: 104px;
  background-color: white;
  color: var(--text-color);
  border: 1px dashed #031a6b;
  border-radius: 8px;
  margin: auto auto 10px auto;
}

.persons-list {
  text-align: left;
}

.person {
  align-self: center;
  padding: 0px 20px;
  width: 30%;
}

.person span {
  height: 32px;
}

.person-details {
  display: flex;
  justify-content: space-between;
}

.person-list-container {
  display: grid;
  grid-template-columns: 5% 90% 5%;
  height: 64px;
}
.person-list-container:nth-of-type(odd) {
  background-color: #1b20210d;
}

.person-list-container span {
  display: block;
}

.person-list-plus {
  margin-top: 24px;
  padding: 0;
  display: grid;
  grid-template-columns: auto auto;
  gap: 4px;
  align-items: center;
  justify-content: flex-start;
  color: var(--secondary-button-color);
  cursor: pointer;
  border: none;
  background-color: transparent;
}

.person-list-plus:disabled {
  color: var(--disabled-button);
}

.person-list-container .person-action {
  justify-self: center;
  align-self: center;
  cursor: pointer;
  color: var(--primary-color);
  transition-duration: 1s;
}

.add-additional-person {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 48px;
}

.add-additional-person svg {
  color: #050b7f;
}

.rotate-arrow {
  transform: rotate(90deg);
}

.close-button {
  margin-right: 16px;
  align-self: center;
  color: var(--secondary-button-color);
}

.add-additional-person .save-button {
  width: 119px;
  height: 48px;
  border: 1px solid var(--primary-color);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--primary-color);
}
