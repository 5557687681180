.with-label {
  grid-template-columns: 33.3% 67%;
}

.without-label {
  grid-template-columns: 100%;
  width: 100%;
}

.two-fields {
  display: grid;
  align-items: flex-start;
}
