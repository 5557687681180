.subheader {
  margin: 32px auto 4px auto !important;
  position: relative;
  display: grid;
  justify-items: normal;
  align-items: end;
}

.subheader .arrow-down {
  position: absolute;
  bottom: 10%;
  left: calc(50% + -12px);
  cursor: pointer;
}

.subheader .left .heading,
.subheader .left .title {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: start;
  gap: 12px;
}

.subheader-body {
  position: absolute;
  z-index: 10;
  left: 0;
  width: 100vw;
  max-height: 0;
  margin-bottom: 20px;
  background-color: #fff;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out, padding 0.5s ease-in-out;
}

.subheader-body.active {
  box-shadow: 0px 2px 4px 0px rgba(128, 130, 130, 0.1);
}

.subheader-body.visible {
  padding-top: 48px;
  padding-bottom: 61px;
  max-height: none;
  height: auto;
}

.subheader-body.hidden {
  padding-top: 0;
  padding-bottom: 0;
  max-height: 0;
}

.subheader-body-content {
  position: relative;
  background-color: #fff;
  z-index: 10;
  display: grid;
  gap: 5%;
  grid-template-columns: 45% 50%;
}

.subheader-body .arrow-down {
  margin-top: 14px;
  z-index: 5;
  cursor: pointer;
  position: relative;
  top: 100%;
  left: 50%;
  z-index: 9;
  margin-left: -12px;
}

.arrow-down path {
  fill: var(--secondary-color);
}

.subheader-body-content img {
  max-height: 269px;
  width: auto;
}

.subheader-body-content.hidden {
  height: 0px;
  display: none;
}

.car-details-image {
  height: 330px;
  max-width: 540px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 10px;
}

.car-information {
  display: grid;
  grid-template-columns: auto auto auto;
  column-gap: 5px;
  text-align: left;
  margin-bottom: 48px;
}

.car-information div {
  margin-top: 15px;
}

.car-information div p:nth-child(odd) {
  color: #808282;
}

.subheader-body-content .cancel-application-text {
  margin-left: -2px;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: left;
  display: inline-flex;
  align-items: baseline;
}

.subheader-body-content .cancel-application-text.error.link:after {
  bottom: -8px;
}

.subheader-body-content .cancel-application-text svg {
  align-self: center;
  margin-top: -2px;
  padding-right: 8px;
}

.subheader-body-content p svg {
  margin-bottom: -5px;
  padding-right: 8px;
  stroke-width: 1px;
}

.subheader-body-content p svg path {
  stroke-width: 4px;
}

.subheader-body-content .cancel-application-text.disabled {
  color: var(--drk-grey);
}

.subheader-body.visible > div > div:nth-child(2) > div:nth-child(3) {
  display: grid;
  justify-content: flex-start;
  align-content: flex-end;
  height: 250px;
}
