.impressum-layout .heading {
  margin-bottom: 80px;
}

.impressum-layout p + h2,
.impressum-layout p + h3 {
  margin-top: 60px;
}

.impressum-layout h2 + h3,
.impressum-layout h3 + p {
  margin-top: 30px;
}
