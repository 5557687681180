.loader-container.credit {
  padding-top: 290px;
  margin-top: auto;
  margin-left: auto;
  animation: linear CreditCheckAnimation 1s;
  -moz-animation: linear CreditCheckAnimation 1s;
  -webkit-animation: linear CreditCheckAnimation 1s;
  -o-animation: linear CreditCheckAnimation 1s;
  animation-iteration-count: 1;
}

.loader-container.credit-out {
  padding-top: 290px;
  margin-top: 0;
  margin-left: -300px;
  animation: linear CreditCheckAnimationOut 1s;
  -moz-animation: linear CreditCheckAnimationOut 1s;
  -webkit-animation: linear CreditCheckAnimationOut 1s;
  -o-animation: linear CreditCheckAnimationOut 1s;
  animation-iteration-count: 1;
}

.loader-container.credit .title {
  opacity: 1;
  animation-delay: 1s;
  animation: linear ShowUp 1s;
  -moz-animation: linear ShowUp 1s;
  -webkit-animation: linear ShowUp 1s;
  -o-animation: linear ShowUp 1s;
  animation-iteration-count: 1;
}

.loader-container.credit .loader {
  opacity: 1;
  animation-delay: 1s;
  animation: linear ShowUp 1s;
  -moz-animation: linear ShowUp 1s;
  -webkit-animation: linear ShowUp 1s;
  -o-animation: linear ShowUp 1s;
  animation-iteration-count: 1;
}

.animation-layout {
  position: relative;
  height: 303px;
  width: 453px;
  padding-bottom: 48px;
}

.credit-check-loader-animate {
  position: absolute;
}

.credit-check-loader-animate:nth-child(1) {
  position: absolute;
  top: 20%;
}

.credit-check-loader-animate:nth-child(2) {
  top: 55%;
  left: 16%;
  width: 170px;
  animation-delay: 1s;
  animation: linear Rotate360 3s;
  -moz-animation: linear Rotate360 3s;
  -webkit-animation: linear Rotate360 3s;
  -o-animation: linear Rotate360 3s;
  animation-iteration-count: infinite;
}

.credit-check-loader-animate:nth-child(3) {
  top: 33%;
  left: 47%;
  width: 120px;
  animation-delay: 1s;
  animation: linear Rotate360AntiClockWise 3s;
  -moz-animation: linear Rotate360AntiClockWise 3s;
  -webkit-animation: linear Rotate360AntiClockWise 3s;
  -o-animation: linear Rotate360AntiClockWise 3s;
  animation-iteration-count: infinite;
}

.credit-check-loader-animate:nth-child(4) {
  z-index: 1;
  width: 198px;
  top: 45%;
  left: 24%;
}

.credit-check-loader-animate:nth-child(5) {
  z-index: 2;
  top: 50%;
  left: 40%;
  width: 172px;
  animation-delay: 1s;
  animation: linear AnimateMagnifier 3s;
  -moz-animation: linear AnimateMagnifier 3s;
  -webkit-animation: linear AnimateMagnifier 3s;
  -o-animation: linear AnimateMagnifier 3s;
  animation-iteration-count: infinite;
}

.credit-check-loader-animate:nth-child(6) {
  z-index: 0;
  height: 137px;
  top: 45%;
  left: 23%;
}

@keyframes CreditCheckAnimation {
  0% {
    margin-top: 0;
    margin-left: -300px;
  }

  100% {
    margin-top: 0;
    margin-left: 0;
  }
}

@keyframes CreditCheckAnimationOut {
  0% {
    margin-top: 0;
    margin-left: 0;
  }

  100% {
    margin-top: 0;
    margin-left: -300px;
  }
}

@keyframes AnimateMagnifier {
  0% {
    top: 45%;
    left: 25%;
  }

  25% {
    top: 45%;
    left: 35%;
  }
  50% {
    top: 55%;
    left: 35%;
  }
  75% {
    top: 55%;
    left: 25%;
  }

  100% {
    top: 45%;
    left: 25%;
  }
}

@keyframes Rotate360 {
  100% {
    -moz-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes Rotate360AntiClockWise {
  100% {
    -moz-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}

@keyframes ShowUp {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes ShowDown {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
