.upload-box-b2c {
  margin-top: 24px;
}

.upload-box-b2c-upload-section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  transition: all 0.7s ease;
  background: var(--upload-button-background);
  border: 1.2px solid var(--button-color);
  box-sizing: border-box;
  color: var(--upload-button-color);
}

.upload-box-b2c-upload-section.disabled {
  pointer-events: none;
  background: var(--light-grey);
  border: 1.2px solid var(--light-grey);
  color: white;
}

.upload-box-b2c-button {
  display: flex;
}
.close-icon:hover {
  stroke: red;
  cursor: pointer;
}

.upload-box-b2c-plus-icon {
  width: 16px;
  height: 16px;
  stroke: var(--upload-button-color);
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.7s ease;
}

.upload-box-b2c-plus-icon.disabled {
  border: 1px solid white;
  stroke: white;
}

.upload-box-details-remove-icon {
  text-align: right;
}

.upload-box-b2c-icon-text {
  padding-left: 10px;
}

.upload-box-b2c-upload-section-footer {
  font-size: 13.335px;
  margin: 8px 0px;
  text-align: left;
}
.upload-box-b2c-upload-section-footer p {
  margin-bottom: 0px;
}

.upload-box-b2c-button input[type='file'] {
  display: none;
  position: absolute;
  top: calc(0% - 20px);
  left: calc(0% - 130px);
  padding: 45px 0;
}

.upload-box-b2c-uploaded-documents-section {
  padding: 16px 20px 16px 16px;
  background: var(--outlined-input-background);
  border-radius: 8px;
  margin: 0 0 12px 60px;
  border: 1px solid var(--upload-button-background);
}
.upload-box-b2c-uploaded-doc-single {
  display: grid;
  grid-template-columns: 10% 85% 5%;
  text-align: left;
  align-items: center;
}
.upload-box-b2c-uploaded-doc-details {
  display: flex;
  flex-direction: column;
}
.upload-box-b2c-uploaded-doc-details p {
  margin-bottom: 0px;
}

.additionalBtn {
  color: #5386e4;
  font-size: 15px;
  cursor: pointer;
  user-select: none;
}

.upload-input-mobile {
  display: none;
}
