.back-next-container {
  display: flex;
  z-index: 10;
  margin-top: 48px;
}

.empty-div {
  width: 50%;
}

.button-group {
  display: grid;
}

.button-group.right {
  justify-content: end;
}

.button-group button[disabled] svg path {
  fill: #c6c7c7;
}

.button-group.spinner-button button {
  color: white;
  background-color: var(--light-grey);
  border-color: var(--light-grey);
}

.button-group.spinner-button.disabled {
  color: gray;
  background-color: var(--light-grey);
  border-color: var(--light-grey);
}

.button-group.spinner-button svg {
  display: none;
}

.button-group .next-button,
.button-group .back-button {
  display: flex;
  align-items: center;
}

.back-button .button-text {
  color: var(--color-primary);
}

.button-group .back-button {
  background: var(--background-color) !important;
  border: none;
  text-align: left;
  margin-top: 0px;
  align-items: baseline;
  padding: 0;
}

.button-group.back-next {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.button-group.back-next.full {
  margin-left: 0px;
}

.back-next.full button {
  width: 100%;
}

.back-next.medium button {
  width: 80%;
  align-self: flex-end;
}

.back-next.small button {
  width: 60%;
}

.back-next.center button {
  align-self: center;
}

.button-group .next-button svg {
  margin-left: 10px;
}

.button-group .back-button {
  color: var(--secondary-button-color);
}

.button-group .back-button svg {
  margin-right: 18px;
}

.button-group .back-button svg path {
  stroke: var(--secondary-button-color);
  fill: var(--secondary-button-color);
  color: var(--secondary-button-color);
}

.button-group--horz button:not(:last-child) {
  margin-right: 10px;
}

.button-text {
  cursor: pointer;
}

.button-ontop {
  position: fixed;
  bottom: 0%;
  left: 0;
  z-index: 1000;
  width: 100%;
  border-radius: 0;
  height: 80px;
}

.button-ontop:hover {
  background-color: white;
}
