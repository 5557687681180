.auth-container {
  width: 100%;
  height: 1000px;
}

.auth-logo {
  position: absolute;
  top: 0;
  width: 414px;
}

.auth-container .content-title {
  color: white;
  text-align: center;
}

.auth-image-container {
  margin-top: 1px;
  float: left;
  width: 50%;
  height: 100%;
  min-height: 1100px;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.auth-image-content {
  width: 70%;
  padding: 0px 120px;
  justify-content: flex-end;
  flex-direction: column;
  display: flex;
  z-index: 1;
}

.auth-image-container.login {
  float: right;
}

.auth-image-container.register {
  float: left;
}

.auth-image-content {
  width: 70%;
  padding: 0px 120px;
  justify-content: flex-end;
  flex-direction: column;
  display: flex;
  z-index: 1;
}

.auth-image-content div {
  align-self: center;
  padding-right: 10px;
}

.auth-form-container {
  float: left;
  width: 50%;
  flex-direction: column;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: auto;
  padding: 96px 0;
  margin-left: -100px;
}

.auth-form-content,
.auth-form-content-success {
  justify-content: flex-end;
  flex-direction: column;
  display: flex;
}

.auth-form-content-success {
  width: 50vh;
  background: #4ea699;
  padding: 15px;
  text-align: center;
  border-radius: 5px;
}

.auth-form-content-success .baseline-normal {
  color: white;
}

.auth-form-container.register {
  float: right;
}

.auth-form-content.login,
.auth-form-content.register,
.auth-image-content.login,
.auth-image-content.register {
  height: auto;
  width: 415px;
}

.error-message {
  text-align: left;
  margin-top: 2vh;
  color: red;
  padding: 10px;
  border-radius: 3px;
}

.error-message .heading {
  margin-bottom: 1vh;
}

.register-text-mobile {
  display: none;
}
