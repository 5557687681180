.choice-box {
  display: grid;
  align-items: center;
}
.choice-text {
  font:
    normal normal 400 1.146em 'Source Sans Pro',
    sans-serif;
}

.checkbox-items {
  display: flex;
}

.choice-text.error {
  color: red;
}

.choice-selection {
  display: grid;
  grid-template-columns: 33.4% 66.1%;
  align-items: center;
}
