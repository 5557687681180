.sequence-container {
  display: grid;
  grid-template-columns: 10% 90%;
  justify-items: center;
  align-items: center;
}

.sequence-container svg {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.sequence-icon {
  width: 250px;
}

.sequence-number {
  z-index: 5;
  justify-self: flex-start;
  font: normal normal 700 96px/35px 'Inter';
  color: #edeef0;
  margin-left: 70%;
  margin-top: 80%;
}

.sequence-container.active svg {
  filter: none;
}

.check-circle {
  margin-top: 2px;
  margin-right: 12px;
  padding: 2px;
  height: 11px;
  width: 11px;
  background-color: #4ea699;
  border-radius: 50%;
}

.check-circle polyline {
  stroke: white;
}

.sequence-container.active .sequence-number {
  color: #5386e4;
}

.sequence-container.complete .sequence-number {
  color: var(--secondary-color);
}
