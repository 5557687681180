.confirm-element-title {
  color: var(--summary-title);
}

.confirm-element-value {
  color: var(--summary-value);
}

.summary-section-title {
  margin-top: 72px;
  margin-bottom: 40px;
}

.title-style {
  margin-bottom: 4px;
}
