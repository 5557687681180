.confirm-section {
  padding: 0 0 32px 0;
  text-align: left;
  display: grid;
  justify-items: baseline;
  grid-template-columns: 158px 316px 158px;
}

.confirm-section:last-child {
  border-bottom: none;
}

.confirm-element-section {
  display: flex;
  justify-content: center;
}

.confirm-element {
  display: grid;
  justify-items: start;
  grid-template-columns: 100px 158px;
}

.confirm-element .baseline-bold,
.confirm-element .sub-bold {
  margin-bottom: 5px;
}

.confirm-section .title {
  display: grid;
  grid-template-columns: 95% 5%;
  margin: 0;
}

.confirm-section .title-b2c {
  display: flex;
  margin: 0;
  justify-content: space-between;
  align-items: baseline;
}
.confirm-section .title-b2c-icon {
  justify-self: end;
  font-size: 15px;
  color: var(--secondary-button-color);
}

.confirm-section .title-b2c-icon span {
  display: block;
}

.confirm-section svg {
  margin-top: 8px;
  margin-left: 15px;
}

.confirm-value {
  width: 230px;
  display: flex;
  margin-left: 90px;
}

.confirm-section svg path {
  stroke: var(--button-color);
}
.forward-icon {
  width: 16px;
  height: 14px;
  margin-left: 5px !important;
  margin-top: 3px !important;
}
