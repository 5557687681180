@import './styles/css-reset.css';
@import './styles/grid.css';
@import './styles/buttons.css';
@import './styles/media-queries.css';

body {
  font-size: calc(16px + (25.5 - 16) * ((100vw - 320px) / (3840 - 320)));
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  overflow-x: hidden;
  hyphens: auto;
  color: var(--text-color);
  background-color: var(--light-theme-bg);
}

[data-framer-component-type] {
  position: inherit !important;
}

main {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
}

.error {
  color: var(--error) !important;
}

a,
.link {
  text-decoration: none;
  color: var(--link-color);
  cursor: pointer;
  position: relative;
}

.error.link {
  color: var(--error);
}

a:visited,
.link:visited {
  color: var(--link-color);
}

.error.link:visited {
  color: var(--error);
}

a:hover,
.link:hover {
  color: var(--link-color-hover);
}

.error.link:hover,
.error.link:hover path {
  color: var(--error-link-color-hover) !important;
  fill: var(--error-link-color-hover) !important;
}

a::after,
.link::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: -4px;
  left: 0;
  background-color: var(--link-color-hover);
  visibility: hidden;
  transition: width 0.3s ease-in-out;
}

.error.link:after {
  background-color: var(--error);
}

a:hover::after,
.link:hover::after {
  visibility: visible;
  width: 100%;
}

a:focus::after,
.link:focus::after {
  visibility: hidden;
  width: 0;
  transition: none;
}

.link-standalone {
  text-decoration: none;
  color: var(--link-color);
  cursor: pointer;
  position: relative;
}

.link-standalone svg,
.link-standalone svg path {
  fill: var(--link-color);
  color: var(--link-color);
}

.link-standalone:hover {
  color: var(--link-color-hover);
}

.link-standalone:hover svg,
.link-standalone:hover svg path {
  fill: var(--link-color-hover);
  color: var(--link-color-hover);
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
label,
input {
  color: var(--text-color);
}

hr {
  border: 0.622189px solid #dedde3;
  width: 100%;
}

ul {
  padding: 0;
}

.default-padding {
  padding: 50px 0;
}

.error-stack {
  color: var(--error) !important;
  text-align: left;
}

.success {
  color: #44a61c;
}

.green {
  color: #4ea699;
}

.red {
  color: #ef476f;
}

.price {
  color: #4ea699;
}

.logo {
  margin-top: 16px;
  max-width: 83px;
}

.space {
  margin: 20px auto;
}

.slideEffect {
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}

.slideEffect.hidden {
  opacity: 0%;
  max-height: 0;
}

.slideEffect.visible {
  opacity: 100%;
  max-height: auto;
}

fieldset {
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-block-start: 0;
  padding-block-end: 0;
  padding-inline-start: 0;
  padding-inline-end: 0;
  border: none;
  min-inline-size: min-content;
}

.hidden {
  display: none;
}

.autocompleteLabel {
  font-weight: bold;
}

.autocompleteInput {
  display: block;
  height: 50px;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  -webkit-appearance: none;
  outline: none;
  padding: 0;
  text-overflow: ellipsis;
  border: 0;
  border-radius: 0;
  color: var(--text-color);
  letter-spacing: -0.24;
  font-weight: 300;
  font-style: normal;
  text-align: center;
  caret-color: var(--button-color);
}

.autocompleteInput::placeholder {
  opacity: 1;
  transition: opacity 0.3s;
}
.autocompleteInput:focus::placeholder {
  opacity: 0;
}

.autocompleteList {
  width: 67%;
  list-style: none;
  text-align: left;
  margin-left: auto;
}

.autocompleteList li {
  color: #808080;
  cursor: pointer;
  padding: 12px 10px;
  position: relative;
  top: 1px;
}

.autocompleteList li:not(:last-child) {
  border-bottom: 1px solid #f2f2f2;
}

.autocompleteList li .matched {
  color: var(--button-color);
}

.autocompleteList li .rest {
  color: var(--text-color);
}

.half-half-columns {
  display: grid;
  grid-template-columns: 50% 50%;
  column-gap: 20px;
  justify-content: center;
  align-items: center;
}

.half-half-rows {
  display: grid;
  grid-template-rows: 50% 50%;
}

.save-and-next-tab {
  width: 230px !important;
  padding: 5px !important;
}

.extra-info {
  display: flex;
  flex-direction: row;
  margin-top: 8vh;
}

.template-body-wide {
  max-width: 1200px;
  margin: auto;
}

.container-width-auto {
  width: auto !important;
}

.unknown-car {
  max-width: 500px;
  margin: 100px auto 50px auto;
}

.divider {
  display: flex;
  align-items: center;
  padding: 10px 0;
}

.divider-thick {
  display: flex;
  align-items: center;
  padding: 15px 0;
}

.divider::before,
.divider::after {
  flex: 1 1;
  content: '';
  padding: 0.622189px;
  background-color: #d9dadd;
  margin: 0px 10%;
}

.divider-thick::before,
.divider-thick::after {
  flex: 1 1;
  content: '';
  padding: 0.622189px;
  background-color: #dedfe1;
  margin: 0px 2%;
}

.back-button {
  margin-left: -5px;
}

.relative {
  position: relative;
}

.pointer {
  cursor: pointer;
}

.bounce {
  animation: bounce 2s linear 4s 2;
}

.float-inline {
  float: inline-end;
}

.top-8 {
  margin-top: 8px;
}

.top-12 {
  margin-top: 12px;
}

.top-16 {
  margin-top: 16px;
}

.top-24 {
  margin-top: 24px;
}

.bottom-24 {
  margin-bottom: 24px !important;
}

.top-36 {
  margin-top: 36px;
}

.top-40 {
  margin-top: 40px;
}

.top-48 {
  margin-top: 48px !important;
}

.top-64 {
  margin-top: 64px !important;
}

.top-72 {
  margin-top: 72px !important;
}

.top-96 {
  margin-top: 96px !important;
}

.top-128 {
  margin-top: 128px !important;
}

.top-136 {
  margin-top: 136px !important;
}

.top-144 {
  margin-top: 144px;
}

.appear-on-front {
  z-index: 1000;
}

.scroll-disable {
  height: 100%;
  overflow: hidden;
}

.home .button-group.default {
  margin-bottom: 16px;
}

.disabled {
  color: var(--light-grey) !important;
}

.disabled svg,
.disabled svg path {
  fill: var(--light-grey) !important;
}

.align-center {
  display: inline-flex;
  align-items: center;
  justify-items: center;
}

.cancel-button {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  color: white;
  fill: white;
}

.cancel-button svg,
.cancel-button svg g path {
  fill: white;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}
