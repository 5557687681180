

.authorities-checkbox {
  display: grid;
  grid-template-columns: 33% 67%;
}

.save-button.next {
  width: 150px;
}

.save-button.next.disabled {
  border-color: var(--light-grey);
  color: var(--light-grey);
  cursor: not-allowed;
}
