.signers-list {
  padding-top: 5vh;
}

.signers-list-legend {
  display: grid;
  grid-template-columns: 25% 25%;
  justify-items: center;
  align-items: center;
}

.signers-list-legend div {
  width: 100%;
  margin-top: 60px;
  display: grid;
  grid-template-columns: 20% 80%;
  justify-content: center;
  align-items: center;
  align-content: center;
  grid-template-rows: 100%;
}

.signer #close-icon path,
.signers-list-legend #close-icon path {
  stroke: red;
}

.signers-list .signer {
  display: grid;
  grid-template-columns: 5% 88% 5%;
  align-items: center;
  padding: 10px 0;
  height: 60px;
}

.signers-list .signer .content-bold {
  margin-bottom: 0 !important;
}

.signers-list .signer .signer-position {
  color: #808282;
}

.signers-list .signer:nth-child(odd) {
  background: var(--collapsible-background);
}

.signers-list .signer .signer-status,
.signers-list .signer path,
.signers-list-legend .signer-status {
  color: #4ea699;
  align-self: center;
  justify-self: center;
}
