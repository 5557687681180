.agb-content h1 {
  margin-top: 20px;
  margin-bottom: 27.5px;
}

.agb-content ol {
  padding: 0 0 17.5px 35px;
}

.agb-content li {
  margin-bottom: 10px;
}
