.save-button {
  width: 119px;
  height: 48px;
  border: 1px solid var(--primary-color);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--primary-color);
}
