.loader-container {
  padding-top: calc(50vh - 250px);
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  width: 100%;
  height: 100%;
  z-index: 250;
  text-align: center;
}

.loader-container h2 {
  margin: 50px auto;
}

.loader {
  display: grid;
  grid-template-columns: 2% 2% 2% 2% 2%;
  align-items: center;
  height: 20px;
  justify-content: center;
}

.loader-ball {
  display: block;
  width: 16px;
  height: 16px;
  background-color: #5386e4;
  border-radius: 50%;
}

.loader-ball:nth-child(1) {
  animation: BallOneAnimation 1s ease-in-out infinite;
}

.loader-ball:nth-child(2) {
  background-color: #9bb6e7;
  width: 12px;
  height: 12px;
  animation: BallOneAnimation 1s ease-in-out infinite;
  animation-delay: 0.2s;
}

.loader-ball:nth-child(3) {
  background-color: #d9d9d9;
  width: 8px;
  height: 8px;
  animation: BallOneAnimation 1s ease-in-out infinite;
  animation-delay: 0.4s;
}

.loader-ball:nth-child(4) {
  background-color: #d9d9d9;
  width: 8px;
  height: 8px;
  animation: BallOneAnimation 1s ease-in-out infinite;
  animation-delay: 0.6s;
}

.loader-ball:nth-child(5) {
  background-color: #d9d9d9;
  width: 8px;
  height: 8px;
  animation: BallOneAnimation 1s ease-in-out infinite;
  animation-delay: 0.8s;
}

@keyframes BallOneAnimation {
  0% {
    width: 16px;
    height: 16px;
    background-color: #5386e4;
  }

  50% {
    background-color: #9bb6e7;
    width: 12px;
    height: 12px;
  }

  75% {
    background-color: #d9d9d9;
    width: 8px;
    height: 8px;
  }

  100% {
    background-color: #d9d9d9;
    width: 8px;
    height: 8px;
  }
}
