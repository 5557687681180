.modal {
  position: fixed;
  top: 0;
  left: 50%;
  width: 100vw;
  height: 100vh;
  transform: translate3d(-50%, 0, 0);
  background-color: var(--background-color);
  z-index: 999;
}

.modal.InAnimated {
  animation: linear ModalTrainsation 1s;
  -moz-animation: linear ModalTrainsation 1s;
  -webkit-animation: linear ModalTrainsation 1s;
  -o-animation: linear ModalTrainsation 1s;
  animation-iteration-count: 1;
}

.modal.transparent {
  background-color: rgba(0, 0, 0, 0.8) !important;
}

.modal-container {
  position: fixed;
  display: flex;
  flex-direction: column;
  outline: none;
  transform: translateX(-50%);
  padding: 10px 10px 0 10px;
  margin: auto;
  left: calc(50% - 115px);
  background-color: #fff;
  border-radius: 8px;
  z-index: 10000;
}

.modal-container.top {
  padding-top: 24px;
  top: 0;
  left: 0;
  width: calc(100% - 24px);
  min-height: 400px;
}

.modal-container.middle {
  left: calc(50% - 480px);
  top: calc(50% - 185px);
  width: 864px;
  text-align: center;
  padding: 48px;
}

.modal-container.full {
  left: 0%;
  top: 40px;
  width: calc(100vw - 30px);
  margin: 0 0 0 5px;
  height: 100%;
  text-align: center;
}

.modal-body .modal-text {
  max-height: 300px;
  text-align: left;
  overflow-y: scroll;
}

.modal-container .modal-body .content-title {
  margin-bottom: 24px;
}

@keyframes ModalTrainsation {
  0% {
    background-color: rgba(255, 255, 255, 0.1);
  }

  10% {
    background-color: rgba(255, 255, 255, 0.2);
  }

  20% {
    background-color: rgba(255, 255, 255, 0.3);
  }

  30% {
    background-color: rgba(255, 255, 255, 0.4);
  }

  40% {
    background-color: rgba(255, 255, 255, 0.5);
  }

  50% {
    background-color: rgba(255, 255, 255, 0.6);
  }

  60% {
    background-color: rgba(255, 255, 255, 0.7);
  }

  70% {
    background-color: rgba(255, 255, 255, 0.8);
  }

  80% {
    background-color: rgba(255, 255, 255, 0.9);
  }

  90% {
    background-color: rgba(255, 255, 255, 1);
  }

  100% {
    background-color: rgba(255, 255, 255);
  }
}
