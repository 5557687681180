.tooltip {
  position: absolute;
  width: 70px;
  left: 30px;
  top: 0;
  background-color: #b3b5b5;
  color: white;
  padding: 2px 4px;
  border-radius: 2px;
}
