.intl-countries-list {
  display: none;
  background-color: var(--light-grey);
  padding: 10px 0;
  width: 431px;
  margin-left: auto;
  border-radius: 3px;
  border-top-left-radius: 0px;
  max-height: 300px;
  overflow: hidden;
  overflow-y: scroll;
  background: var(--background-color);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 60px;
  left: 0;
  z-index: 3;
}

.intl-country {
  cursor: pointer;
}

.intl-countries-list.active {
  display: block;
}

.intl-countries-list::-webkit-scrollbar {
  display: none;
  width: 10px;
}

.intl-countries-list::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: var(--drk-grey);
}

.intl-countries-list::-webkit-scrollbar-thumb:hover {
  background: #4ea699;
}

.intl-country {
  padding: 0 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 25px;
}

.intl-country span:nth-child(1) {
  padding-right: 5px;
}

.intl-country span:nth-child(2) {
  font-weight: bold;
  width: auto;
  padding-right: 10px;
}

.intl-country span:nth-child(3) {
  font-weight: bold;
}

.intl-country:nth-child(3) {
  margin-bottom: 8px;
}

.intl-country:hover {
  background: #4ea699;
  color: white;
}
