.checkbox p {
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0;
}

.search-result {
  width: 100%;
  min-width: 500px;
}

.search-result-content {
  min-height: 400px;
}

.search-result-content .interactive-choices {
  grid-template-columns: 100%;
}

.search-result-extra {
  display: flex;
  flex-direction: row;
}

.no-match {
  display: grid;
  grid-template-columns: 23px auto;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  justify-items: center;
}

.no-match:hover {
  cursor: pointer;
}

.no-match svg path {
  color: var(--secondary-button-color) !important;
  stroke: var(--secondary-button-color) !important;
  fill: var(--secondary-button-color);
}

.no-match span {
  color: var(--secondary-button-color);
}

.no-match svg {
  width: 16px;
  height: 14px;
}

.result {
  margin-top: 96px;
  display: grid;
  align-items: center;
  grid-template-columns: 50% 50%;
  column-gap: 50px;
}

.result-title {
  margin-bottom: 24px;
}
