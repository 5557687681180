.arrow-list-item {
  display: grid;
  grid-template-columns: auto 96%;
  gap: 12px;
  align-items: center;
  text-align: left;
  margin-bottom: 24px;
}

.arrow-list-item:last-child {
  margin-bottom: 0px;
}

.arrow-list-item p svg {
  height: 20px;
  margin-top: 0px;
}

.arrow-list-item svg {
  align-self: start;
}
