.document-layout {
  margin: 72px 0 48px 0;
}

.upload-documents-personal-documents {
  margin-bottom: 24px;
  background: var(--outlined-input-background);
  border-radius: 8px;
  border: 1px dotted #f6f5f8;
}
.upload-documents-personal-documents > div:first-child {
  border-bottom: 1px dotted #b7b7b7;
}
.upload-documents-personal-documents > div {
  padding: 16px 16px 8px 0px;
}

.upload-documents-checkbox-content {
  padding: 0px 0px 0px 12px !important;
  color: var(--text-color);
}
.upload-documents-checkbox {
  padding: 16px 16px 8px 0px;
}

.upload-with-label .label {
  align-self: flex-start;
}

.form-content {
  margin-top: 0px;
}

.form-section-title {
  margin-bottom: 48px;
}
