.checkbox {
  display: grid;
  grid-template-columns: 24px auto;
  gap: 4px;
  align-items: center;
  position: relative;
  margin-bottom: 16px;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
}

.input-box.checkbox-with-label label {
  width: 100%;
}

.checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkbox-content {
  color: var(--grey);
}

.checkbox-content span,
.checkbox-content p {
  margin-top: 2px;
  display: block;
}

.checkmark {
  grid-row: 1;
  display: inline-block;
  background-image: url('../icons/checkbox-blank.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 24px;
  width: 24px;
  margin-bottom: -2.4px;
}

.checkmark.error {
  border: 3px solid red;
}

.checkmark.square {
  border-radius: 2px;
  top: 0px;
}

.checkmark.middle {
  top: 35%;
}

.checkmark.middle.round-left {
  top: 30%;
}

.checkbox input:checked ~ .checkmark {
  background-image: url('../icons/checkbox-checked.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.checkbox input:checked ~ .checkmark.round {
  background-color: transparent;
}

.checkbox.round {
  padding-left: 40px;
}

.checkbox .checkmark.round:after,
.checkbox .checkmark.round-left:after {
  left: 3px;
  top: 3px;
  height: 10px;
  width: 10px;
  background-color: #4ea699;
  border-radius: 50%;
  border: none;
  border-width: none;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
}

.interactive-choices .checkmark.round {
  border-width: 3px;
}

.checkmark.round {
  height: 16px;
  width: 16px;
  left: 14px;
}

.checkmark.round-left {
  height: 15px;
  width: 15px;
}
