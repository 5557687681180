.tabs-container {
  display: inline-block;
  margin-top: 30px;
  width: auto;
  white-space: nowrap;
}

.tab-item {
  text-align: center;
  margin: 10px 24px 10px 0;
  cursor: pointer;
  display: inline-block;
  justify-content: center;
  align-items: center;
}

.tab-title {
  color: #4ea699;
}

.active-tab {
  font: normal normal 700 12px/15px 'Inter';
  padding-bottom: 8px;
  border-bottom: #4ea699 3px solid;
}

.disable-tab {
  color: #808282;
  pointer-events: none;
}
