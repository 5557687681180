.button {
  min-width: 157px;
  height: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  filter: brightness(100%) grayscale(0);
  font-weight: 500;
  padding: 0.5em 1em;
  background-color: var(--button-color);
  color: var(--white);
  border: 1px solid;
  border-radius: 8px;
  cursor: pointer;
  outline: none;
  text-align: center;
  transition: all 0.1s ease-out;
}

.button:hover {
  background-color: #04269f;
  border-color: #04269f;
}

.button:active {
  background-color: #031863;
  border-color: #031863;
}

.button:disabled {
  background-color: #b3b5b5;
  border-color: #b3b5b5;
}

.button.loading {
  background-color: #5264a5;
  border-color: #5264a5;
}

.button--primary {
  background-color: var(--primary-color, #031a6b);
  border-color: var(--primary-color, #031a6b);
  color: #fff;
}

.button--primary:hover {
  background-color: #04269f;
  border-color: #04269f;
}

.button--primary:active {
  background-color: #031863;
  border-color: #031863;
}

.button--primary:disabled {
  background-color: #b3b5b5;
  border-color: #b3b5b5;
}

.button--primary.loading {
  background-color: #5264a5;
  border-color: #5264a5;
}

.button--secondary {
  color: #031a6b;
  border-color: #031a6b;
  background-color: white;
}

.button--secondary:hover {
  color: #04269f;
  border-color: #04269f;
  background-color: rgba(82, 100, 165, 0.25);
}

.button--secondary:active {
  color: #031863;
  border-color: #031863;
  background-color: white;
}

.button--secondary:disabled {
  color: #b3b5b5;
  border-color: #b3b5b5;
  background-color: white;
}

.button--secondary.loading {
  color: #5264a5;
  border-color: #5264a5;
  background-color: white;
}

.button--tertiary {
  color: white;
  border-color: #4ea699;
  background-color: #4ea699;
}

.button--tertiary:hover {
  color: white;
  border-color: #66b7ab;
  background-color: #66b7ab;
}

.button--tertiary:active {
  color: white;
  border-color: #418b80;
  background-color: #418b80;
}

.button--tertiary:disabled {
  color: white;
  border-color: #b3b5b5;
  background-color: #b3b5b5;
}

.button--tertiary.loading {
  color: white;
  border-color: #75aca4;
  background-color: #75aca4;
}

.button--danger {
  background-color: #ef476f;
  border-color: #ef476f;
  color: #fff;
}

.button--danger:hover {
  background-color: #eb1e4e;
  border-color: #eb1e4e;
}

.button--danger:active {
  background-color: #ed315d;
  border-color: #ed315d;
}

.button--danger:disabled {
  background-color: #b3b5b5;
  border-color: #b3b5b5;
}

.button--danger.loading {
  background-color: #e98aa0;
  border-color: #e98aa0;
}

.button.large {
  min-width: 280px;
  margin: auto;
}

button.long {
  width: 460px;
  height: 48px;
}

button.full {
  width: 100%;
}

.button .backward-arrow {
  align-self: flex-start;
}

.button .forward-arrow {
  align-self: flex-end;
}

.button--clear {
  background-color: transparent;
  border-color: transparent;
  color: var(--text-color);
  height: auto;
  width: auto;
  padding: 0;
}

.button--micro {
  height: 36px;
  border-radius: 18px;
}

.button--arrow {
  width: 110px;
  margin: 0 auto;
}

.button-choice {
  background: #ffffff;
  border: 1px solid #b7b7b7;
  box-sizing: border-box;
  border-radius: 10px;
  color: var(--text-color);
}

.button-choice:hover,
.button-choice.active {
  border: 1px solid var(--primary-color) !important;
  color: inherit;
}

.icon {
  display: flex;
}

button .icon {
  display: flex;
  align-items: center;
}

.button--arrow.button--previous .icon > div {
  justify-content: flex-start;
}

.button--arrow.button--next .icon > div {
  justify-content: flex-end;
}

.car-selection {
  margin: 16px 0px;
}
