.contract .upload-box {
  cursor: pointer;
}

.description div {
  margin-top: 16px;
}

.download-box {
  border: 1px solid #e2e2e2;
  border-radius: 8px;
  padding: 14px 0;
  color: var(--primary-color);
  margin-top: 20px;
  margin-bottom: 40px;
  cursor: pointer;
  display: block;
}

.download-box svg,
.download-box path {
  margin-right: 8px;
  fill: var(--primary-color);
}

.download-box-b2c {
  padding: 10px 0;
  color: var(--primary-color);
  cursor: pointer;
  display: grid;
  grid-template-columns: 10% 90%;
  text-align: left;
  align-items: center;
  text-align: left;
}

.download-box-b2c svg,
.download-box-b2c path {
  margin-right: 8px;
}
.download-box-b2c svg {
  color: #1b2021 !important;
  fill: #1b2021 !important;
}
.download-box-b2c path {
  fill: var(--primary-color);
}

.file-name {
  padding-left: 20px;
  align-self: center;
}

.checkbox-insurance-box {
  display: grid;
  margin-top: 24px;
  grid-template-columns: 100%;
  justify-items: baseline;
  border-radius: 8px;
  transition: all 0.7s ease;
  align-content: center;
  position: relative;
}

.upload-box-details-contract {
  display: grid;
  grid-template-columns: 40px 100%;
  text-align: left;
  margin-left: 30px;
}

.contract .upload-box-actions path {
  fill: white;
}
