.select-box-main {
  text-align: left;
  position: relative;
  margin: 0 auto;
  justify-content: space-between;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.select-box-container {
  width: 100%;
}

.select-box-main .label {
  width: 50%;
  background-color: transparent;
}

.select-box {
  width: 100%;
  height: 48px;
  border: 1px solid var(--border-color);
  box-sizing: border-box;
  border-radius: 3px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-repeat: no-repeat;
  background-position-x: 96%;
  background-position-y: 18px;
  text-align: left;
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-transition-duration: 300ms;
  -moz-transition-duration: 300ms;
  -o-transition-duration: 300ms;
  transition-duration: 300ms;
  cursor: pointer;
  background-image: url('../../b2b/components/icons/chevron-down.svg');
}
.select-box .input-text {
  border: none;
}

.box-input-error {
  box-shadow: 0px 3px 0px #ef476f !important;
}

.select-box svg {
  padding: 12px 0px 12px 16px;
}

.select-box input {
  width: 85%;
  color: #393838;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  vertical-align: middle;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 16px;
  height: 100%;
  line-height: normal;
}

.select-box.focused {
  box-shadow: 0px 3px 0px #031a6b;
  margin-bottom: 0px;
}

.select-box .disabled {
  border: 1px solid var(--light-grey);
}

.options {
  text-align: left;
  width: calc(70% - 24px);
  margin: 20px auto;
  background: var(--background-color);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  min-height: 100%;
  overflow: hidden;
  overflow-y: scroll;
}

.options::-webkit-scrollbar {
  width: 10px;
  display: none;
}

.options::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px var(#ffff);
}

.options::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: var(--drk-grey);
}

.options::-webkit-scrollbar-thumb:hover {
  background: var(--primary-color);
}

.options.hidden {
  position: relative;
  z-index: none;
  width: 0px;
}

.options.visible {
  position: absolute;
  z-index: 1000;
  border-radius: 4px;
}

.options li {
  cursor: pointer;
  padding: 10px 10px;
}

.options.visible li {
  padding: 10px 10px;
}

.options li.highlighted {
  color: #ffffff;
  background: #4ea699;
}

.options.hidden li {
  opacity: 0;
  transition: all 0.5s ease-in-out;
}

.select-box-error {
  margin: 5px 0 0 0;
}
