.upload-container {
  margin-bottom: 20px;
}

.upload-container.aligned {
  width: calc(70% + 35px);
  margin-left: auto;
}

.upload-box {
  display: grid;
  height: 72px;
  grid-template-columns: 70% 30%;
  justify-items: baseline;
  border: 1px solid #c6c7c7;
  border-radius: 4px;
  transition: all 0.7s ease;
  align-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.upload-box-container-progress {
  grid-template-columns: 100%;
  border-radius: 4px;
  border: 1px solid #dddede;
}

.upload-box-progress-text {
  position: absolute;
  top: calc(50% - 15px);
  left: calc(50% - 65px);
  color: var(--text-color);
}

.upload-filename {
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.upload-box-progress {
  background-color: #4ea698;
  color: white;
  box-shadow: none !important;
  display: grid;
  grid-template-columns: 100%;
  justify-items: center;
  align-items: center;
  transition: all 0.7s ease;
  height: 72px;
}

.upload-box-details-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.upload-box-details {
  width: 100%;
  display: grid;
  grid-template-columns: 55px auto;
  text-align: left;
  margin-left: 30px;
}

.upload-box-details-upload-icon-box,
.upload-box-details-check-mark-icon-box {
  align-self: center;
}

.upload-box-details-upload-icon,
.upload-box-details-check-mark-icon,
.upload-box-details-delete-icon {
  display: block;
}

.upload-box-details-upload-icon-box svg {
  stroke: #dddde3;
}

.upload-box-details-check-mark-icon-box svg {
  stroke: #4ea699;
}

.upload-box-details-check-mark-icon {
  stroke: var(--success);
}

.upload-box-details-text {
  margin-bottom: 8px !important;
}

.upload-box-file-text {
  color: #5386e4;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.upload-box-actions {
  display: flex;
  margin-right: 16px;
  justify-items: end;
  justify-content: center;
  align-items: center;
  width: 100%;
  justify-self: flex-end;
  cursor: pointer;
}

.upload-box-new-actions {
  display: flex;
  margin-right: 10px;
  justify-items: end;
  justify-content: center;
  align-items: center;
  width: 100%;
  justify-self: flex-end;
  cursor: pointer;
}

.upload-box-actions label {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.upload-box-actions-text {
  color: var(--link-color);
  transition: all 0.7s ease;
}

.upload-box-actions input[type='file'] {
  display: none;
  position: absolute;
  top: calc(0% - 20px);
  left: calc(0% - 130px);
  padding: 45px 0;
}

.upload-box-actions-plus-icon,
.upload-box-details-delete-icon {
  width: 24px;
  height: 24px;
  display: block;
  margin: 0 5px 0 auto;
  cursor: pointer;
  transition: all 0.7s ease;
  fill: var(--link-color);
}

.upload-box-actions-plus-icon svg path {
  stroke: var(--link-color);
}

.upload-box-details-delete-icon-white {
  display: none;
}

.upload-box-actions-text.delete {
  color: var(--error);
}

.upload-box-details-delete-icon g path {
  fill: var(--error) !important;
}

.upload-box-details-delete-icon-white g path {
  fill: white !important;
}

.progress-upload {
  position: relative;
  display: flex;
  border: 1px solid #c6c7c7;
  border-radius: 4px;
  height: 72px;
  overflow: hidden;
}

.back {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #4ea698;
  color: white;
}

.front {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: white;
  color: #808282;
  transition: clip-path 1s linear;
}

.upload-box-mobile {
  display: flex;
  flex-direction: column;
  height: 157px !important;
  border: 1px solid #dddede;
  border-radius: 4px;
  transition: all 0.7s ease;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
}

.upload-box-details-box-mobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.upload-box-details-box-mobile {
  width: 100%;
  text-align: left;
}

.upload-box-actions-mobile {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.upload-box-actions-mobile label {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.upload-box-actions-mobile input[type='file'] {
  display: none;
  position: absolute;
  top: calc(0% - 20px);
  left: calc(0% - 130px);
  padding: 45px 0;
}
