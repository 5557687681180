.background-name {
  font: normal normal 700 305px 'Inter' !important;
  opacity: 5%;
  position: absolute;
  top: calc(50% - 28vh);
  left: calc(50% - 480px);
  z-index: -1;
}

.car-main {
  display: grid;
  grid-template-columns: 45% 55%;
  padding-top: 25px;
  width: 100%;
  gap: 3%;
  justify-content: flex-start;
  align-content: start;
}

.car-main div:nth-child(1) {
  justify-self: start;
  margin-left: -5px;
}

.car-main div:nth-child(2) {
  justify-self: end;
  align-self: end;
}

.car-details {
  padding-top: 48px;
  display: grid;
  width: 100%;
  margin: auto;
  gap: 5%;
  grid-template-columns: 45% 50%;
  justify-content: center;
  align-content: start;
}

.car-visual {
  text-align: left;
}

.car-visual-img {
  margin-bottom: 16px;
  max-width: 540px;
}

.car-details svg {
  max-width: 580px;
  margin: auto;
}

.car-details .offer-details {
  text-align: left;
}

.car-details .car-name,
.car-details .offer {
  position: relative;
  text-align: left;
  line-height: 1em;
}

.pill {
  text-align: center;
  background: #f29559;
  border-radius: 4px;
  color: #ffffff;
  padding: 2px 8px;
  max-height: 25px;
  max-width: 75px;
  float: right;
}

.pill.leasing {
  background: #5386e4;
}

.car-details .price {
  color: #4ea698;
  margin-bottom: 45px;
}

.car-details .consumption {
  margin-bottom: 50px;
}

.lease-details,
.lease-more-details {
  display: flex;
  flex-wrap: wrap;
}

.lease-info {
  margin-top: 48px;
}

.lease-info.last {
  margin-bottom: 48px;
}

.lease-details span {
  flex: 50%;
}

.lease-key {
  flex: 50%;
  text-align: left;
}

.lease-value {
  flex: 50%;
  text-align: right;
}
