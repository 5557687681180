.idnow-info {
  border-radius: 16px;
  text-align: center;
}

.idnow-info .heading {
  margin-top: 48px;
  margin-bottom: 56px !important;
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  justify-content: center;
  text-align: left;
}

.idnow-info .title svg {
  margin-left: 10px;
}

.idnow-step-title {
  margin-top: 24px !important;
}

.idnow-info-details {
  display: grid;
  grid-template-columns: 33% 33% 33%;
  grid-column-gap: 50px;
  padding: 0 55px;
  justify-content: center;
  align-self: center;
  justify-items: center;
}

.idnow-info-steps {
  display: grid;
  grid-template-columns: 100%;
  justify-items: self-start;
  max-width: 250px;
  position: relative;
}

.idnow-info-steps svg {
  max-width: 200px;
  height: 120px;
  align-self: center;
  justify-self: center;
}

.idnow-info-steps p {
  margin-top: 16px;
  text-align: left;
}

.idnow-step {
  font: normal normal 700 96px 'Inter' !important;
  color: #4ea698;
  position: absolute;
  top: 15px;
  left: 0;
}

.idnow-step-description {
  min-height: 80px;
}

.idnow-extra {
  margin: 30px;
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 500px;
}

.idnow-info-button {
  display: flex;
  margin: 40px 0px;
  justify-content: center;
}
