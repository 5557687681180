.car-selector {
  display: grid;
  padding: 50px;
  grid-template-columns: 60% 40%;
  box-shadow: 0px 2px 20px rgba(171, 170, 170, 59%);
  border-radius: 16px;
  transition: transform 0.5s;
}

.car-selector:hover {
  transform: scale(1.05);
}

.car-selector .title {
  padding-bottom: 15px;
}

.car-selector .description {
  margin-bottom: 10px;
}

.car-price img {
  max-width: 250px;
}

.car-price {
  display: flex;
  margin: auto;
  flex-direction: column;
}

.car-selector svg {
  width: auto;
  margin: auto;
}

.car-selector-cushion {
  font-size: 12px;
}

.car-selector button {
  max-width: 312px;
}

.car-selector-content .title .price {
  display: none;
}

.car-selector-content .price-desktop {
  justify-self: left;
  color: #4ea699;
  display: block;
}

.car-selector-content .content-title {
  padding-top: 32px;
  padding-bottom: 15px;
}

.car-selector-content .title .price {
  display: none;
}
