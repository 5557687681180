.footer {
  margin-top: 48px;
  font-size: 14px !important;
  padding: 60px 0 40px 0;
  background-color: var(--footer-background);
  color: var(--footer-text);
}

.footer .grid-template {
  height: initial;
}

.footer .row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.footer .left-column {
  margin: 0 0 30px 0;
  flex-grow: 1;
  text-align: left;
}

.footer .right-column {
  margin: 0 0 30px 0;
  text-align: left;
}

.footer .full-width-column {
  text-align: left;
}

.footer li {
  margin: 0 0 10px 0;
}

.footer li a {
  color: var(--footer-text);
}

.footer li a:hover {
  color: var(--footer-text-hover);
}

.footer li a::after {
  background-color: var(--footer-text-hover);
}
